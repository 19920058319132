import type { GetServerSideProps, GetServerSidePropsResult } from 'next'
import { useRouter } from 'next/router'
import { Article, Category } from '../../backend/models'
import { HomeLayout } from '../../components/layouts/HomeLayout'
import { TopArticles } from '../../components/TopArticles'
import { TopCategories } from '../../components/TopCategories'
import { handleAndGetTenantAsync, PageBaseProps } from '../../utils/tenants'
import { KbContext } from '../../frontend/KbContext';
import { AppContext } from '../../frontend/AppContext';
import { getAllCategories, getFeaturedCategories } from '../../backend/queries/categories'
import { getAllArticles, getFeaturedArticles } from '../../backend/queries/articles'
import Head from 'next/head'
import treelizeCategories from '../../utils/treelizeCategories'
import { getFileUrl } from '../../utils/imaging'
import { removeQueryParamsFromRouter, shouldLanguageRedirect } from '../../utils/localeHandling'
import { getCanonicalURL } from '../../utils/seo/getCanonicalURL'

type Props = PageBaseProps & {
    featuredCategories: Array<Category>,
    allCategories: Array<Category>,
    allArticles: Array<Article>,
    featuredArticles: Array<Article>
}

export default function Home(props: Props) {
    const router = useRouter();
    removeQueryParamsFromRouter(router, ['r', 'lang_selector']); // remove the "r"

    const isEmbedded = router.query.embed == '1';
    const noNav = router.query.nonav == '1';

    const treelizedCats = treelizeCategories(props.allCategories);

    return <AppContext.Provider value={{ tenant: props.tenant, auth: props.auth }}>
        <Head>
            <title>{props.tenant.title}</title>
            <link rel='icon' datatype='image/png' href={getFileUrl(props.tenant.icons.favIconFileId)} />
            {props.canonical?.usingCustomDomain && <link rel='canonical' href={props.canonical?.canonicalUrl} />}
            <meta property="og:image" content={getFileUrl(props.tenant.icons.logoFileId)}></meta>
        </Head>
        <KbContext.Provider value={{ allArticles: props.allArticles, allCategories: props.allCategories, treelizedCategories: treelizedCats }}>
            <HomeLayout isEmbedded={isEmbedded} noNav={noNav}>
                <div className="mt-10 lg:-mt-10">
                    <TopCategories categories={props.featuredCategories} />
                </div>
                <div className="mx-auto mt-20 max-w-3xl p-4">
                    <TopArticles articles={props.featuredArticles} />
                </div>
            </HomeLayout>
        </KbContext.Provider>
    </AppContext.Provider>
}

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
    const { tenant, auth } = await handleAndGetTenantAsync(ctx);
    
    const locale = ctx.params?.language;
    if (tenant.supportedLanguages.findIndex(x => x === locale) == -1){
        return {
            notFound: true
        }
    }

    if (!auth.isFromAllowedIP) {
        return {
            notFound: true
        }
    }

    if (!auth.isAuthorized) {
        return {
            redirect: {
                permanent: false,
                destination: `/${locale}/auth/signin`,
            },
            props: {},
        }
    }
    const allCats = await getAllCategories(tenant);    
    const cats = await getFeaturedCategories(tenant); // check if we really need to get this from db
    const articles = await getAllArticles(tenant, auth?.isAuthenticated);
    const featuredArticles = await getFeaturedArticles(tenant, auth?.isAuthenticated);

    const canonical = getCanonicalURL(tenant, ctx.resolvedUrl);

    return {
        props: {
            featuredCategories: cats,
            allCategories: allCats,
            allArticles: articles,
            featuredArticles: featuredArticles,
            tenant: tenant,
            auth: auth,
            canonical
        }
    }
}